module.exports = {
  siteTitle: 'B/S/H/', // <title>
  manifestName: 'WE/ARE FIVE/WE ARE ONE/',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'WE/ARE FIVE',
  heading2: '/WE ARE ONE/',
  subHeading: 'A MOVIE BY',
  subHeading2: 'ALL OF US',

  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/bsh-home-appliances-group',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/thebshgroup',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/bshgroup.DE/',
    },
  ],
  email: 'random@bshg.com',
  phone: '000-00000',
  address: '305 Del Sol, Sunnyvale, CA',

};
